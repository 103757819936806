* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Quicksand', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--gray-dark);
}

a.blanco {
  color: #fff;
}

.icon-menu {
  color: white;
  font-size: 25px;
  margin-top: 10px;
  display: none !important;
}

.title {
  text-align: center;
  color: #0097dc;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.title h2 {
  background: white;
  padding: 0px 20px;
}

.title .line {
  background: #0097dc;
  width: 400px;
  height: 4px;
  position: absolute;
  margin-top: 15px;
  z-index: -1;
}

.header {
  background-image: url('../images/cover-page.webp');
  background-size: cover;
  background-position: center;
  height: 650px;
}

.header::before {
  content: '';
  width: 100%;
  height: 650px;
  background: rgba(0, 0, 0, 0.712);
  position: absolute;
}

header {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: 20px 80px;
}

header .logotype img {
  width: 100px;
}

header nav {
  display: flex;
  align-items: center;
}

header nav li {
  list-style: none;
  padding: 0px 15px;
}

header nav li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

/* START - MENU RESPONSIVE */

.menu-responsive {
  transition: transform 0.3s;
  transform: translateX(-100%);
  position: relative;
  z-index: 99;
  display: none;
}

@media screen and (max-width: 950px) {
  .menu-responsive {
    display: block;
  }
}

.menu-responsive__nav {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  position: absolute;
  z-index: 99;
  width: 100%;
}

.menu-responsive::before {
  content: '';
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
}

.menu-responsive__li {
  list-style: none;
}

.menu-responsive__a {
  color: var(--blue-light);
  text-decoration: none;
  display: block;
  padding: 20px 0px;
  font-weight: 700;
  font-size: 18px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2px;
  color: white;
  margin-top: 80px;
  text-align: center;
}

.text b {
  color: #0097dc;
}

.text p {
  width: 550px;
  text-align: center;
  margin-top: 10px;
  line-height: 1.5;
}

.socials-icons {
  display: flex;
  justify-content: center;
  color: white;
  font-weight: bold;
  position: relative;
  z-index: 2;
  text-align: center;
  margin-top: 30px;
}

.socials-icons .content {
  margin: 0px 20px;
}

.socials-icons .content .icon {
  font-size: 24px;
}

.socials-icons .content p {
  margin-top: 5px;
}

.scroll-icon {
  width: 50px;
  position: relative;
  z-index: 2;
  margin-top: 100px;
}

.about-us {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 80px;
}

.about-us .text-about {
  width: 550px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  margin-right: 50px;
}

.about-us .text-about h2 {
  color: #0097dc;
  margin: 0em;
  font-size: 32px;
}

.about-us .text-about .line {
  background: #0097dc;
  width: 200px;
  height: 4px;
  position: relative;
  margin: 10px auto;
}

.about-us .text-about p {
  margin-top: 10px;
  line-height: 1.5;
  color: #707070;
}

.about-us .img {
  width: 600px;
  height: 350px;
  background-image: url('../images/team-about-us-min.webp');
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}

.services {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 0px;
}

.services .content {
  width: 400px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 25px 30px;
  color: white;
  margin: 10px;
  transition: all 0.3s;
  cursor: pointer;
}

.services .content:hover {
  transform: scale(1.05);
}

.services .content img {
  width: 50px;
  position: relative;
  z-index: 2;
}

.services .content .title-services {
  font-weight: bold;
  font-size: 18px;
  position: relative;
  z-index: 2;
}

.services .content .description-services {
  margin-top: 5px;
  font-size: 15px;
  line-height: 1.5;
  position: relative;
  z-index: 2;
}

/*SERVICES BACKGROUND AND IMAGES*/

.services .web-design {
  background-image: url('../images/web-development-min.webp');
  background-size: cover;
  background-position: center;
  position: relative;
}

.services .web-design::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(45deg, #ff22d3ce, #63118cd2);
  border-radius: 10px;
}

.services .app-design {
  background-image: url('../images/mobile-development-min.webp');
  background-size: cover;
  background-position: center;
  position: relative;
}

.services .app-design::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(45deg, rgba(0, 213, 255, 0.849), rgba(8, 8, 179, 0.849));
  border-radius: 10px;
}

.services .graphic-design {
  background-image: url('../images/graphic-design-min.webp');
  background-size: cover;
  background-position: center;
  position: relative;
}

.services .graphic-design::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(45deg, #fd9744ea, #fa8131e7);
  border-radius: 10px;
}

.services .community-manager {
  background-image: url('../images/community-manager-min.webp');
  background-size: cover;
  background-position: center;
  position: relative;
}

.services .community-manager::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(45deg, #e35a8ae0, #6f1333);
  border-radius: 10px;
}

.services .marketing-digital {
  background-image: url('../images/marketing-digital-min.webp');
  background-size: cover;
  background-position: center;
  position: relative;
}

.services .marketing-digital::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(45deg, #fd79a7e5, #e84393ee);
  border-radius: 10px;
}

/*------------------------------*/

@media screen and (max-width: 970px) {
  .about-us {
    flex-direction: column;
    padding: 20px;
  }

  .about-us .text-about {
    margin-right: 0px;
  }
}

@media screen and (max-width: 950px) {
  .icon-menu {
    display: block !important;
  }

  header nav {
    display: none;
  }
}

@media screen and (max-width: 620px) {
  header {
    padding: 20px 25px;
  }

  .about-us .text-about {
    width: auto;
  }

  .about-us .img {
    width: 90%;
    height: 300px;
  }
}

@media screen and (max-width: 570px) {
  .text p {
    width: 90%;
  }

  .scroll-icon {
    margin-top: 50px;
  }
}

@media screen and (max-width: 520px) {
  .text h1 {
    font-size: 24px;
    padding: 0px 10px;
  }

  .about-us .text-about h1 {
    font-size: 25px;
  }

  .about-us .text-about .line {
    width: 150px;
  }

  .title .line {
    display: none;
  }

  .container-title__name-section::before {
    display: none;
  }

  .container-title__name-section::after {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .about-us .img {
    width: 98%;
    height: 250px;
    border-radius: 10px;
  }
}

/* START - CONTENEDOR DE TITULO */

.container-title {
  display: flex;
  justify-content: center;
}

.container-title__name-section {
  position: relative;
  color: var(--blue-light);
  margin: 30px 0px 20px 0px;
}

.container-title__name-section::before,
.container-title__name-section::after {
  position: absolute;
  content: '';
  width: 100px;
  height: 4px;
  border-radius: 24px;
  background-color: currentColor;
  top: 12px;
  margin: 0px 20px;
}

.container-title__name-section::before {
  left: 100%;
}

.container-title__name-section::after {
  right: 100%;
}

/*  END - CONTENEDOR DE TITUTLOS */

/* START - TEAM */

.team {
  background-color: var(--gray-light);
  width: 100%;
  margin: 50px 0px 0px 0px;
}

.team__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.team__person {
  width: 280px;
  max-height: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  padding: 25px;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0px 0px 3px rgb(212 212 212 / 80%);
}

.team__image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0px 0px 20px 0px;
}

.team__name {
  font-weight: 700;
  font-size: 17px;
  padding: 0px 0px 10px 0px;
  color: var(--gray-dark);
  text-align: center;
}

.team__area {
  color: var(--gray-dark);
  padding: 5px;
  font-weight: 600;
  text-align: center;
}

.team__rol {
  color: var(--gray-dark);
  padding: 10px 0px 0px 0px;
  text-align: center;
  line-height: 1.2rem;
}

.glide__arrow {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--blue-light);
}

.glide__arrow--right {
  right: 1em;
}

.glide__arrow--left {
  left: 1em;
}

/* END - TEAM */

/* START - PORTFOLIO*/

.portfolio__container-description {
  width: 100%;
  display: flex;
  justify-content: center;
}

.portfolio__text-description {
  width: 60%;
  text-align: center;
}

.portfolio__container-work {
  margin: 30px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.portfolio__work {
  position: relative;
  border-radius: 7px;
  width: 400px;
  height: 220px;
  margin: 10px;
}

.portfolio__work::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  background: rgb(2, 0, 36);
  background: linear-gradient(360deg, rgba(2, 0, 36, 1) 0%, rgba(255, 255, 255, 0) 35%);
}

.portfolio__image {
  width: 100%;
  height: inherit;
  border-radius: inherit;
  object-fit: cover;
}

.portfolio__container-texts {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.portfolio__name {
  color: #fff;
  font-weight: 700;
  padding: 20px 20px 0px 20px;
  font-size: 18px;
  text-shadow: 0px 0px 2px rgb(0, 0, 0);
}

.portfolio__description {
  color: #fff;
  padding: 5px 20px 20px 20px;
}

.portfolio__button {
  min-width: 150px;
  display: block;
  background-color: #fff;
  text-align: center;
  color: var(--gray-dark);
  text-decoration: none;
  border-style: none;
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0px 40px 0px 0px;
}

/* END - PORTFOLIO*/

/* START - FORM CONTACT */

.form-alert {
  width: 100%;
  position: fixed;
  padding: 20px;
  top: 0;
  left: 0;
  transition: transform 0.6s ease;
  transform: translateX(-100%);
  z-index: 99;
  background-color: rgb(0, 201, 0);
}

.form-alert__info {
  font-weight: 700;
  color: #fff;
}

.form-contact {
  width: 1000px;
  height: 650px;
  display: flex;
  box-shadow: 0px 0px 3px var(--gray-dark);
  border-radius: 10px;
  margin: 100px auto;
  background-color: #fff;
}

.form-contact__left {
  width: 370px;
  height: 100%;
  position: relative;
}

.form-contact__left::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.7;
  top: 0;
  left: 0;
  border-radius: 10px 0px 0px 10px;
  background-color: var(--blue-light);
}

.form-contact__image {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 10px 0px 0px 10px;
}

.form-contact__container-texts {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 55;
}

.form-contact__name {
  font-weight: 700;
  color: #fff;
  font-size: 30px;
  text-align: center;
  margin: 70px 0px 0px 0px;
}

.form-contact__description {
  color: #fff;
  padding: 20px;
  text-align: center;
}

.form-contact__right {
  width: 100%;
}

.form-contact__container-title {
  width: 100%;
}

.form-contact__title {
  margin: 60px 0px 0px 0px;
  color: var(--blue-light);
  text-align: center;
}

.form-contact__line-title {
  background-color: var(--blue-light);
  width: 200px;
  height: 3px;
  margin: 15px auto;
  border-radius: 24px;
}

.form-contact__group-input {
  display: flex;
}

.form-contact__container-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  margin: 40px 0px 0px 0px;
}

.form-contact__c-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px;
  width: 100%;
}

.form-contact__input {
  background-color: var(--gray-light);
  border-style: none;
  outline: 0 !important;
  display: block;
  padding: 10px;
  width: 100%;
}

.form-contact__input.textarea {
  height: 150px;
}

textarea {
  resize: none;
}

.form-contact__label {
  color: var(--gray-dark);
  font-weight: 700;
  padding: 10px 0px;
}

.form-contact__container-button {
  margin: 10px 15px;
  padding: 20px 0px;
  display: flex;
  justify-content: flex-end;
}

.form-contact__button-submit {
  background-color: var(--blue-light);
  color: #fff;
  font-weight: 700;
  padding: 10px 40px;
  border: transparent;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .form-contact {
    width: 97%;
  }
}

@media screen and (max-width: 900px) {
  .form-contact {
    flex-direction: column;
    height: auto;
  }

  .form-contact__left {
    display: none;
  }

  .form-contact__group-input {
    flex-direction: column;
  }
}

/* END - FORM CONTACT*/

.content-footer {
  display: flex;
  justify-content: space-between;
  background-color: #292727;
  padding: 30px 50px;
}

.content-footer .necodt-text {
  width: 500px;
}

.content-footer .necodt-text p {
  color: white;
  line-height: 1.5;
  font-weight: 100;
}

.content-footer .necodt-text img {
  width: 100px;
}

.content-footer .info-contact {
  width: 400px;
  margin-top: 50px;
}

.content-footer .info-contact .title-info {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.content-footer .info-contact .info {
  color: white;
  margin: 20px;
  line-height: 1.5;
}

.content-footer .info-contact .icon-info {
  margin-right: 10px;
}

footer {
  background: #0097dc;
  color: white;
  font-weight: bold;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.idiomas {
  color: #fff;
  border: solid #fff 1px;
  padding: 10px;
  text-decoration: none;
}

.idiomas:hover {
  color: #ffff00;
  border: solid #ffff00 1px;
  transition: 0.2s ease-in;
}

@media screen and (max-width: 1100px) {
  .content-footer .necodt-text {
    width: 450px;
  }

  .content-footer .info-contact {
    width: 300px;
  }
}

@media screen and (max-width: 900px) {
  .content-footer {
    flex-direction: column;
  }

  .content-footer .necodt-text {
    width: auto;
  }

  .content-footer .info-contact {
    width: auto;
  }
}

@media screen and (max-width: 400px) {
  .content-footer {
    padding: 30px 20px;
  }
}